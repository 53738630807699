<template>
  <CardDiv
    v-tc-loader-bar="loading"
    :loading="loading || null"
    class="response-rate"
  >
    <template #body>
      <div class="response-rate-wrapper p-card">
        <bar-graph
          v-if="isBarGraph"
          :question-type="''"
          :rows="rows"
          :categories="['']"
          :skeleton-loader="loading"
        />
        <TcChart
          v-else-if="isRadialGraph"
          ref="tcChart"
          :class="{ 'tc-chart-radial': isRadialGraph }"
          :card="localCard"
          :type="chartType"
          :has-data="!loading"
          :chart-data="chartDataRadial"
          :overwrite-chart-data="chartDataRadial.chartOptions"
        />
        <TcChart
          v-else
          ref="tcChart"
          :type="chartType"
          :has-data="!loading"
          :card="localCard"
          :chart-data="chartDataLine"
          :overwrite-chart-data="chartDataLine.chartOptions"
        />
        <div
          v-if="isRadialGraph"
          class="tc-response-rate-description"
        >
          <p class="mb-1">
            <span class="strong">{{ segmentLabel }}:</span> {{ cardDescription.segment }}
          </p>
          <p>
            <span class="strong">{{ (customerName || 'Customer') }}:</span> {{ cardDescription.customer }}
          </p>
        </div>
      </div>
    </template>
  </CardDiv>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { klona } from 'klona';
import {
  merge, isNaN, isError, isEmpty, isEqual, debounce, forOwn, isNumber,
} from 'lodash-es';
import { useStore } from 'vuex';
import responseRateAPI from 'API/responseRate';
import { formatFiltersForRequest } from 'API/data';
import { translateTerm, equalLiteral, trimString, roundNumber } from 'Utils/general';
import { KEY_METRIC_GRAPH_TYPES } from 'Utils/graph';
import { creamColors } from 'Utils/chartColors';
import eventBus from 'Utils/eventBus';
import { useLineGraph, useLineGraphResponseRate } from 'Composables/useLineGraph';
import CardDiv from 'Components/parts/CardDiv';
import TcChart from 'Components/parts/graph/TCChart';
import BarGraph from 'Components/parts/graph/BarGraph';
import gettext from '@/gettext';

const { useUpdateSeries, useSetLegends } = useLineGraph();
const { prepareLineGraphDbResponses } = useLineGraphResponseRate();

const store = useStore();
const { $pgettext, $gettext } = gettext;

const EMPTY_ARRAY_2 = new Array(2).fill(0);
const EMPTY_ARRAY_3 = new Array(3).fill(0);

const sortByDataAsc = (a, b) => {
  if (isNaN(a.data[0]) || isNaN(b.data[0])) {
    if (isNaN(a.data[0])) return -1;
    if (isNaN(b.data[0])) return 1;
    return 0;
  }
  return a.data[0] - b.data[0];
};
const sortByDataDesc = (a, b) => {
  if (isNaN(a.data[0]) || isNaN(b.data[0])) {
    if (isNaN(a.data[0])) return 1;
    if (isNaN(b.data[0])) return -1;
    return 0;
  }
  return b.data[0] - a.data[0];
};

const props = defineProps({
  card: {
    type: Object,
    // required: true,
  },
  board: {
    type: Object,
    // required: true,
  },
  compiledFilter: {
    type: Object,
    default: () => ({}),
  },
  compiledBenchmark: {
    type: Object,
    default: () => ({}),
  },
  compiledCompare: [Object, null],
  contextBenchmark: {
    type: Object,
    default: () => ({}),
  },
  isInBoard: {
    type: Boolean,
    default: false,
  },
  previewMode: {
    type: Boolean,
    default: false,
  },
  hydrateProps: { // ? Fill in comp from outside
    type: [Object, null],
    default: null,
  },
});

const emit = defineEmits(['loading', 'hydrate-props', 'open-edit-modal']);

const tcChart = ref(null);
const loading = ref(true);
// const saving = ref(false);
const currentDataSeries = ref([]); // ? Ex. 'radialBar', [76, 67, 61] or for 'line', [{name: 'Global', data:[34.9, 33.8]}, {name: 'Customer', data:[67, 12]}]
const dbResponses = ref({});
const abortToken = ref(Math.random().toString(10).substring(2));
const debouncedTrigger = ref(() => {});

const segmentId = computed(() => store.getters.segmentId);
const segmentName = computed(() => store.getters.segmentName);
const customerId = computed(() => store.getters.customerId);
const customerName = computed(() => store.getters.customerName);

const modalCard = computed(() => store.getters.modalCard);
const localCard = computed(() => (props.previewMode ? modalCard.value : props.card));

const chartType = computed(() => localCard.value?.metadata.graphType?.settings?.[KEY_METRIC_GRAPH_TYPES.ResponseRate]?.type || 'radialBar');
const isBarGraph = computed(() => chartType.value === 'bar');
const isLineGraph = computed(() => chartType.value === 'line');
const isRadialGraph = computed(() => chartType.value === 'radialBar');

const benchmarkFilter = computed(() => {
  /* eslint-disable max-len */
  const benchmarkSize = modalCard.value?.metadata?.benchmark?.size || props.card?.metadata?.benchmark?.size || [];
  const benchmarkSector = modalCard.value?.metadata?.benchmark?.sector || props.card?.metadata?.benchmark?.sector || [];
  const benchmarkLocation = modalCard.value?.metadata?.benchmark?.location || props.card?.metadata?.benchmark?.location || [];
  const benchmarkIndustry = modalCard.value?.metadata?.benchmark?.industry || props.card?.metadata?.benchmark?.industry || [];
  return {
    ...(!isEmpty(benchmarkSize) && { size: benchmarkSize }
      || !isEmpty(props.contextBenchmark?.size) && { size: props.contextBenchmark.size }),
    ...(!isEmpty(benchmarkSector) && { sector: benchmarkSector }
      || !isEmpty(props.contextBenchmark?.sector) && { sector: props.contextBenchmark.sector }),
    ...(!isEmpty(benchmarkLocation) && { country: benchmarkLocation }
      || !isEmpty(props.contextBenchmark?.location) && { country: props.contextBenchmark.location }),
    ...(!isEmpty(benchmarkIndustry) && { industry: benchmarkIndustry }
      || !isEmpty(props.contextBenchmark?.industry) && { industry: props.contextBenchmark.industry }),
  };
  /* eslint-enable max-len */
});
const hasBenchmarkFilter = computed(() => {
  if (isEmpty(props.compiledBenchmark) || props.compiledBenchmark?.general?.includes('global')) return false;
  return Object.values(benchmarkFilter.value)
    .reduce((acc, bench) => (bench?.length >= 0 ? acc + bench.length : acc), 0) > 0;
});
const hideBenchmark = computed(() => localCard.value?.metadata.show?.columns?.benchmarks !== true);

const steps = computed(() => store.getters.customerAllSteps || []);
const colors = computed(() => {
  if (loading.value) return [...(hideBenchmark.value ? EMPTY_ARRAY_2 : EMPTY_ARRAY_3)].fill('#aaa');
  return creamColors[3];
});

const currentGroupBy = computed(() => {
  if (!isBarGraph.value && isLineGraph.value) return { groupBy: ['date'] };
  if (!isBarGraph.value || isEmpty(props.compiledCompare)) return {};
  if (props.compiledCompare?.key === 'segment') return { groupBy: ['segment'] };
  if (props.compiledCompare?.tag) return { groupBy: [`tag:${props.compiledCompare.tag}`] };
  if (props.compiledCompare?.key) return { groupBy: [props.compiledCompare.key.toLowerCase()] };
  if (props.compiledCompare?.key === undefined && props.compiledCompare?.tag === undefined) return { groupBy: ['date'] };
  return {};
});
const isGroupedBy = computed(() => !isEmpty(currentGroupBy.value));

const rows = computed(() => {
  if (!isBarGraph.value) return [];
  if (loading.value) return EMPTY_ARRAY_3.map((v, i) => ({ data: [i + 0.75], label: 'Loading', name: '', color: creamColors[3][i] }));
  if (currentDataSeries.value?.length && currentDataSeries.value.some((s) => s !== 0)) {
    const dataSeries = hideBenchmark.value
      ? currentDataSeries.value.filter((serie, i, arr) => i < arr.length - 1)
      : currentDataSeries.value;
    return dataSeries.map(({ name, data, label, count, totalCount }, idx) => {
      let color = colors.value[0];
      if (hideBenchmark.value) {
        if (idx === dataSeries.length - 1) color = colors.value[1]; // ? Customer
      } else {
        // eslint-disable-next-line no-lonely-if
        if (idx === dataSeries.length - 1) color = colors.value[2]; // ? Global
        else if (idx === dataSeries.length - 2) color = colors.value[1]; // ? Customer
      }
      return { count: [count], data: [data], label, name, totalCount, color };
    }).sort(localCard.value?.metadata?.show?.sort?.orderAsc === false ? sortByDataDesc : sortByDataAsc);
  }
  return currentDataSeries.value.map((serie) => ({
    count: [serie], data: [serie], label: '', name: '',
  })).sort(localCard.value?.metadata?.show?.sort?.orderAsc === false ? sortByDataDesc : sortByDataAsc);
});

const seriesComputed = computed(() => {
  if (loading.value) return hideBenchmark.value ? EMPTY_ARRAY_2 : EMPTY_ARRAY_3;
  if (currentDataSeries.value?.length > 0) {
    return (hideBenchmark.value
      ? currentDataSeries.value.filter((_, i) => i < 2)
      : currentDataSeries.value).map((serie) => serie?.data || (isBarGraph.value || isLineGraph.value ? [] : NaN));
  }
  return [...(hideBenchmark.value ? EMPTY_ARRAY_2 : EMPTY_ARRAY_3)].fill(NaN);
});

const cardDescription = computed(() => {
  const notEnoughDataText = $gettext('Ingen insamlad feedback');
  const loadingText = $gettext('Laddar…');
  const segmentResp = dbResponses.value?.[0]?.[0];
  const customerResp = dbResponses.value?.[1]?.[0];
  let customerText = notEnoughDataText;
  let segmentText = notEnoughDataText;
  if (segmentResp?.responded_count && segmentResp?.responded_count) {
    segmentText = `${segmentResp?.responded_count}/${segmentResp?.applicant_count} ${$gettext('besvarade formuläret')}`;
  }
  if (customerResp?.responded_count && customerResp?.responded_count) {
    customerText = `${customerResp?.responded_count}/${customerResp?.applicant_count} ${$gettext('besvarade formuläret')}`;
  }
  return {
    segment: !loading.value ? segmentText : loadingText,
    customer: !loading.value ? customerText : loadingText,
  };
});

const useBenchmarkLabel = computed(() => !isEmpty(benchmarkFilter.value) && !props.compiledBenchmark?.general?.includes('global'));
const segmentLabel = computed(() => {
  const exclude = ['date', 'step'];
  const filter = Object.keys(props.compiledFilter).reduce((result, key) => {
    if (!exclude.includes(key)) result[key] = props.compiledFilter[key];
    return result;
  }, {});

  const hasFilter = Object.keys(filter).length;

  return !hasFilter ? segmentName.value || 'Segment' : $pgettext('Response rate - Segment label', 'Filtrerad data');
});

const labelsComputed = computed(() => [
  segmentLabel.value || 'Segment',
  customerName.value || 'Customer',
  ...(hideBenchmark.value ? [] : [useBenchmarkLabel.value ? 'Benchmark' : 'Global']),
]);

const title = computed(() => {
  const maxLen = 24;
  const string = translateTerm(props?.compiledFilter?.step?.[0]) || '';
  return {
    text: trimString(string, maxLen),
    fontSize: string.length > maxLen ? '14px' : '16px',
    offsetY: string.length > maxLen ? 154 : 152,
    ...(isLineGraph.value && { offsetY: 0 }),
  };
});

const chartTotal = computed(() => {
  if (loading.value) return { label: $pgettext('Loading - ResponseRate Chart', 'Laddar…'), color: '#aaa', offsetY: 46 };
  const segmentOrCustomer = [seriesComputed.value[0], seriesComputed.value[1]];
  const firstIndex = segmentOrCustomer.findIndex((serie) => !isNaN(serie));
  const label = labelsComputed.value[firstIndex];
  const color = colors.value[firstIndex];
  let offsetY = 12;
  if (firstIndex > -1) offsetY = 46;
  else if (seriesComputed.value[2]) offsetY = 36;
  return {
    label: label || $pgettext('Error - Response Rate', 'Ingen insamlad feedback'),
    color: color || '#aaa',
    offsetY,
  };
});

// const maxTotal = computed(() => {
//   const max = Math.max(...series);
//   if (max === 0 || isNaN(max)) return 100;
//   return max + (max * 0.08);
// });

const watchedTrigger = computed(() => ({
  cardMetadata: props.card?.metadata,
  compiledCompare: props.compiledCompare,
  compiledFilter: props.compiledFilter,
  benchmarkFilter: hasBenchmarkFilter.value && benchmarkFilter.value || {},
  compiledBenchmark: props.compiledBenchmark,
  steps: steps.value,
}));

onBeforeUnmount(() => {
  eventBus.$emit(`abortRequest:${abortToken.value}`);
  if (tcChart.value) { tcChart.value?.destroy(); }
});

const refreshApexchart = () => {
  if (isLineGraph.value) return;
  if (!loading.value) {
    loading.value = true;
    nextTick(() => {
      loading.value = false;
    });
  }
};

const rescaleResponseRate = (responseRate, benchmarkRR, newBenchmarkRR) => {
  // responseRate, benchmarkRR, and newBenchmarkRR are all between 0 and 1
  if (responseRate < benchmarkRR) return newBenchmarkRR * (responseRate / benchmarkRR) ** 3;
  return (
    newBenchmarkRR + (1 + ((responseRate - benchmarkRR) / (1 - benchmarkRR) - 1) ** 3) * (1 - newBenchmarkRR)
  );
};

function convertResponseRates(filteredRR, customerRR, benchmarkRR) {
  // Create a new benchmark between 0.5 and 1 (so a rescaled benchmark from 0 to 100)
  const fRR = filteredRR / 100;
  const cRR = customerRR / 100;
  const bRR = (isNaN(benchmarkRR) || hideBenchmark.value ? 0 : benchmarkRR) / 100;
  const rescaledBenchmark = 0.5 + 0.5 * bRR;

  const newFilteredRR = rescaleResponseRate(fRR, bRR, rescaledBenchmark) * 100;
  const newCustomerRR = rescaleResponseRate(cRR, bRR, rescaledBenchmark) * 100;
  const newBenchmarkRR = isNaN(benchmarkRR) ? NaN : rescaledBenchmark * 100;

  return [newFilteredRR, newCustomerRR, ...(hideBenchmark.value ? [] : [newBenchmarkRR])];
}

const prepareCustomerQueries = (step) => {
  const { date } = props.compiledFilter;
  return {
    filter: {
      date,
      step: { name: step ?? props?.compiledFilter?.step?.[0] },
      customer_proxy: { customer_id: customerId.value },
    },
    ...(
      (!isBarGraph.value && isLineGraph.value) && { groupBy: ['date'] }
    ),
  };
};

const prepareSegmentQueries = (step) => {
  const { tags, segment, date, customer_proxy } = formatFiltersForRequest(props.compiledFilter);
  const applicant = { ...(tags && { tags }) };
  const customerProxy = { customer_proxy: { ...customer_proxy, ...{ customer_id: customerId.value } } };
  let segmentForQuery = null; // ? BarGraph uses segmentQueries for the groupby, so we shouldn’t restrict it.
  if (!isEmpty(segment)) {
    segmentForQuery = segment;
  } else if (!currentGroupBy.value?.groupBy?.includes('segment')) {
    segmentForQuery = { segment_id: segmentId.value };
  }
  return {
    filter: {
      ...(!isEmpty(applicant) && { applicant }),
      ...(segmentForQuery && { segment: segmentForQuery }),
      ...(customerProxy),
      ...(date && { date }),
      step: { name: step ?? props?.compiledFilter?.step?.[0] },
    },
    ...currentGroupBy.value,
  };
};

const prepareBenchmarkQueries = (step) => {
  const { date } = props.compiledFilter;
  return {
    filter: {
      date,
      ...(hasBenchmarkFilter.value && { customer_proxy: benchmarkFilter.value }),
      step: { name: step ?? props?.compiledFilter?.step?.[0] },
    },
    ...(
      (!isBarGraph.value && isLineGraph.value) && { groupBy: ['date'] }
    ),
  };
};

const getSegmentStats = async (step) => {
  if (segmentId.value) return responseRateAPI.stats(prepareSegmentQueries(step), abortToken.value);
  return Promise.reject(new Error('[TC] Couldn’t get segmentId'));
};

const getCustomerStats = async (step) => {
  if (customerId.value) return responseRateAPI.stats(prepareCustomerQueries(step), abortToken.value);
  return Promise.reject(new Error('[TC] Couldn’t get customerId'));
};

const getBenchmarkStats = async (step) => responseRateAPI.statsGlobal(prepareBenchmarkQueries(step), abortToken.value);

const getResponseRates = async (step) => Promise.allSettled([
  getSegmentStats(step), // i: 0
  getCustomerStats(step), // i: 1
  getBenchmarkStats(step), // i: 2
  // ...(hideBenchmark ? [] : [getBenchmarkStats(step)]), // i: 2 — Was giving too many issues with skipping the benchmark, so now we just hide it unfortunately.
]).then((results) => results.map(
  (result) => (result.reason
      || Array.isArray(result.value) ? result.value.flat() : result.value),
))
  .then((results) => {
    if (isBarGraph.value && isGroupedBy.value) {
      const resultsWithNoEmptyArrays = results.map((entry) => (entry.length === 0 ? [{}] : entry));
      return resultsWithNoEmptyArrays.flat();
    }
    return results;
  }) // TODO: Not sure yet if we should have a combination of isBarGraph & isGroupedBy.value, or isLineGraph or so.
  .catch(() => {});

const responseRatesToDataSeries = (stepResponses = []) => stepResponses?.map?.((step, i) => {
  if (isBarGraph.value && isGroupedBy.value) {
    let label = step?.date_str || step?.segmentname || step?.customerproxyname || step?.value || step?.key || i; // ? Date/Segment/Proxy/Tag
    if (!hideBenchmark.value && i === stepResponses.length - 1) label = labelsComputed.value[2]; // ? Global/Benchmark
    else if (i === stepResponses.length - 2) label = labelsComputed.value[1]; // ? Customer
    const data = roundNumber((step.respond_perc) * 100, 1) ?? NaN;
    const count = step?.responded_count ?? 0;
    return { name: '', data, label, count, totalCount: step?.applicant_count };
  }

  if (isLineGraph.value && Array.isArray(step)) {
    let label = [];
    let data = [];
    let count = [];
    let totalCount = [];
    step.forEach((entry) => {
      label.push(entry?.date_str || entry?.segmentname || entry?.customerproxyname || entry?.value || entry?.key || i); // ? Date/Segment/Proxy/Tag
      data.push(entry?.respond_perc ? roundNumber((entry.respond_perc) * 100, 1) : null);
      count.push(entry?.responded_count ?? 0);
      totalCount.push(entry?.applicant_count ?? 0);
    });

    return { name: '', data, label, count, totalCount };
  }

  // if (chartType === 'radialBar'):
  const label = labelsComputed.value[i];
  const count = step?.[0]?.responded_count ?? 0;
  const totalCount = step?.[0]?.applicant_count ?? 0;
  let data = step?.respond_perc ?? 0;
  if (isError(step) || step?.[0]?.error) data = NaN;
  if (i <= 2) data = roundNumber((step?.[0]?.respond_perc || 0) * 100, 1) || NaN;
  return {
    name: label,
    data,
    label,
    ...(count && { count }),
    ...(totalCount && { totalCount }),
  };
}) ?? [];

const setCurrentDataSeries = (step, dataSeries) => {
  if (!steps.value.includes(step)) return;
  currentDataSeries.value = dataSeries;
};

const shouldTrim = computed(
  () => !!(props.compiledFilter?.date?.span?.allTime || props.compiledFilter?.date?.unprocessedDate?.span?.allTime),
);
const setup = async (step) => {
  loading.value = true;
  if (step && steps.value.includes(step) && currentDataSeries.value.length === 0) {
    const hydratedProps = props.hydrateProps?.[KEY_METRIC_GRAPH_TYPES.ResponseRate];
    const useSavedResponse = isEqual(hydratedProps?.segmentQuery, prepareSegmentQueries())
      && isEqual(hydratedProps?.customerQuery, prepareCustomerQueries())
      && isEqual(hydratedProps?.benchmarkQuery, prepareBenchmarkQueries())
      && hydratedProps?.dbResponses;
    try {
      let responseRates;
      if (useSavedResponse) {
        responseRates = hydratedProps.dbResponses;
      } else {
        responseRates = await getResponseRates(step);
      }
      dbResponses.value = isLineGraph.value
        ? prepareLineGraphDbResponses(responseRates, shouldTrim.value)
        : responseRates;

      setCurrentDataSeries(step, responseRatesToDataSeries(dbResponses.value));
    } catch (error) {
      console.error('[TC] Couldn’t get any response rates', error);// eslint-disable-line no-console
    }
  }
  refreshApexchart();
  loading.value = false;
};

onMounted(() => {
  debouncedTrigger.value = debounce((shouldSetup) => {
    if (shouldSetup) {
      currentDataSeries.value = [];
      setup(props?.compiledFilter?.step?.[0]);
    }
  }, 100, { leading: false, trailing: true });

  setup(props?.compiledFilter?.step?.[0]);
  const timer = setTimeout(refreshApexchart, 1000);
  eventBus.$on(`abortRequest:${abortToken.value}`, () => {
    clearTimeout(timer);
  });
});

watch(() => watchedTrigger.value, (newVal, oldVal) => {
  let runSetup = false;
  if (!equalLiteral(newVal.compiledCompare, oldVal.compiledCompare)) runSetup = true;
  if (!equalLiteral(newVal.benchmarkFilter, oldVal.benchmarkFilter)) runSetup = true;
  // if (!equalLiteral(newVal.steps, oldVal.steps)) runSetup = true;
  if (!equalLiteral(newVal.compiledFilter, oldVal.compiledFilter)) runSetup = true;
  if (!equalLiteral(
    newVal?.cardMetadata?.graphType?.settings?.[KEY_METRIC_GRAPH_TYPES.ResponseRate]?.type,
    oldVal?.cardMetadata?.graphType?.settings?.[KEY_METRIC_GRAPH_TYPES.ResponseRate]?.type,
  )) {
    runSetup = true;
  }
  if (!equalLiteral(newVal.steps, oldVal.steps)) runSetup = true;
  if (runSetup) debouncedTrigger.value(runSetup);
});

watch(() => loading.value, (newVal) => { if (!newVal) emit('loading', newVal); }, { immediate: true });// ? Triggers fullyLoadedCards so Sejda knows when to print

watch(() => segmentId.value, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    dbResponses.value = {};
    currentDataSeries.value = [];
    setup(props?.compiledFilter?.step?.[0]);
  }
});

watch(() => hideBenchmark.value, (newVal, oldVal) => {
  if (props.previewMode && newVal !== oldVal) {
    if (isBarGraph.value) {
      dbResponses.value = {};
      currentDataSeries.value = [];
      setup(props?.compiledFilter?.step?.[0]);
    } else if (newVal === false) {
      currentDataSeries.value = [];
      setup(props?.compiledFilter?.step?.[0]);
    }
  }
});

watch(() => useBenchmarkLabel.value, (newVal, oldVal) => {
  if (newVal !== oldVal && newVal !== undefined) {
    const responses = isLineGraph.value
      ? prepareLineGraphDbResponses(dbResponses.value, shouldTrim.value)
      : dbResponses.value;
    setCurrentDataSeries(props?.compiledFilter?.step?.[0], responseRatesToDataSeries(responses));
  }
});

watch(() => dbResponses.value, (newVal, oldVal) => {
  if (props.isInBoard && !equalLiteral(newVal, oldVal)) {
    emit('hydrate-props', { [KEY_METRIC_GRAPH_TYPES.ResponseRate]: {
      dbResponses: newVal,
      segmentQuery: prepareSegmentQueries(),
      customerQuery: prepareCustomerQueries(),
      benchmarkQuery: prepareBenchmarkQueries(),
    } });
  }
});

const zoomedSeries = computed(
  () => convertResponseRates(seriesComputed.value[0], seriesComputed.value[1], seriesComputed.value[2])
    .map((serie) => roundNumber(serie, 1)),
);

const metaLegends = computed(() => props.card?.metadata?.show?.graph?.series || []);
const chartDataLine = computed(() => {
  if (!isLineGraph.value) return {};
  const chartOptions = {
    grid: { xaxis: { lines: { show: false } } },
    labels: labelsComputed.value,
    yaxis: { labels: { formatter: (val) => (isNumber(val) ? `${roundNumber(val, 1)}%` : '-') } },
    chart: {
      events: {
        legendClick: ((context, chart) => (chartContext, seriesIndex, config) => {
          // it's currently always using thewrong series labels when using topic linegraph
          const clickedLegend = context.series[seriesIndex].name || '';
          const serieLegends = (klona(metaLegends.value) || []).map((serie, i) => {
            if (serie.name === clickedLegend) serie.visible = !serie.visible;
            return serie;
          }); // {name, visible}

          useUpdateSeries({
            legends: serieLegends,
            card: props.card,
            board: props.board,
            db: true,
            previewMode: props.previewMode,
          });
        })((this, tcChart.value?.$refs?.apex)),
      },
    },
  };
  const placeholderData = {
    series: [],
    labels: labelsComputed.value,
    chartOptions,
  };

  if (!Array.isArray(currentDataSeries.value[0]?.data)) return placeholderData;
  const series = currentDataSeries.value.reduce((acc, serie, idx) => {
    if (idx >= labelsComputed.value.length) return acc;
    acc.unshift({
      name: labelsComputed.value[idx],
      data: serie.data,
      label: serie.label,
      count: serie.count,
      totalCount: serie.totalCount,
    });
    return acc;
  }, []);

  const sortedDbResponsesByLength = [...dbResponses.value].sort((a, b) => b.length - a.length);
  const linegraphLabels = [];
  if (sortedDbResponsesByLength?.length) {
    sortedDbResponsesByLength?.[0].forEach((response) => linegraphLabels.push(response?.date_str));
  }

  const chartData = {
    series,
    labels: linegraphLabels,
    chartOptions,
  };
  if (loading.value) return merge({}, chartData, placeholderData);
  return chartData;
});

const chartDataRadial = computed(() => {
  if (!isRadialGraph.value) return {};
  const placeholderData = {
    series: hideBenchmark.value ? [50, 40] : [50, 40, 30],
    labels: labelsComputed.value,

    chartOptions: {
      labels: labelsComputed.value,
      chart: { type: 'radialBar', animations: { enabled: false } },
      plotOptions: {
        radialBar: {
          dataLabels: {
            total: { formatter() { return ''; } },
            value: { show: false },
          },
        },
      },
      markers: { size: 0 },
      legend: { formatter() { return ''; } },
    },
  };

  const origSeries = seriesComputed.value;
  const seriesObj = zoomedSeries.value
    .reduce((acc, zoomedSerie, i) => ({ ...acc, [zoomedSerie]: origSeries[i] }), {});

  const chartData = {
    series: zoomedSeries.value,
    labels: labelsComputed.value,
    chartOptions: {
      chart: {
        type: 'radialBar',
        animations: { enabled: false },
        dropShadow: { enabled: false },
      },
      grid: {
        padding: {
          top: 60,
          right: -30,
          left: -30,
        },
      },
      colors: colors.value,
      labels: labelsComputed.value,
      plotOptions: {
        radialBar: {
          colors: colors.value,
          labels: labelsComputed.value,
          id: abortToken.value,
          offsetY: 0,
          startAngle: 0,
          endAngle: 300,
          hollow: {
            margin: 5,
            size: '50%',
            background: 'transparent',
            image: undefined,
            dropShadow: {
              enabled: false,
            },
          },
          track: {
            background: '#e0e0e0',
            strokeWidth: '100%',
          },
          dataLabels: {
            total: {
              show: true,
              label: chartTotal.value.label,
              color: chartTotal.value.color,
              fontWeight: 'normal',
              formatter(w) {
                const firstSerie = origSeries.find((serie) => !isNaN(serie));
                return firstSerie === undefined || isNaN(firstSerie) ? '' : `${firstSerie}%`;
              },
            },
            name: {
              show: true,
              offsetY: chartTotal.value.offsetY,
              // formatter: (w) => 'ya',
              floating: true,
            },
            value: {
              show: true,
              formatter(val) {
                return `${seriesObj?.[val]}%`;
              },
              fontSize: '32px',
              fontFamily: "'Trim-Mediumm', sans-serif",
              offsetY: 0,
              color: '#4a4a4a',
            },
          },
        },
      },
      legend: {
        inverseOrder: false,
        show: true,
        floating: true,
        fontSize: '14px',
        horizontalAlign: 'right',
        position: 'left',
        offsetX: '50%',
        offsetY: hideBenchmark.value ? 28 : 14,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
          width: 0,
          height: 0,
        },
        formatter(seriesName, opts) {
          return `${seriesName} ${
            isNaN(origSeries[opts.seriesIndex]) ? '' : `${origSeries[opts.seriesIndex]}%`
          }`;
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      title: {
        text: title.value.text,
        align: 'center',
        floating: true,
        offsetY: title.value.offsetY,
        margin: -60,
        style: {
          fontSize: title.value.fontSize,
          fontWeight: 'normal',
          color: '#4a4a4a',
        },
      },
      stroke: {
        lineCap: 'round',
        dashArray: 0,
      },
      tooltip: {
        enabled: false,
      },
      // responsive: [{
      //   breakpoint: undefined,
      //   options: {},
      // }],
    },
  };

  if (loading.value) return merge({}, chartData, placeholderData);
  return chartData;
});

const toqLegends = computed(() => chartDataLine.value.series.reduce((acc, legend, idx) => {
  let isVisible = true;
  return { ...acc, [legend.name]: isVisible };
}, {}));

const legendsArray = computed(() => {
  let mismatch = false;
  if (metaLegends.value.length === 0) mismatch = true;
  metaLegends.value.forEach((legend) => {
    if (toqLegends.value[legend.name]?.visible) return;
    mismatch = true;
  });
  if (mismatch) {
    const legends = [];
    const metaLegendsMap = new Map(metaLegends.value.map((legend) => [legend.name, legend.visible]));
    forOwn(toqLegends.value, (visible, name) => legends.push({ name, visible: metaLegendsMap.get(name) ?? visible }));
    return legends;
  }
  return metaLegends;
});

onMounted(() => {
  if (!isLineGraph.value) return;
  useSetLegends({
    legends: legendsArray.value,
    hasMoreLegends: false,
    apexRef: tcChart.value?.$refs?.apex,
  });
});

watch(
  [
    () => localCard.value,
    () => chartDataLine.value,
  ],
  () => {
    if (!isLineGraph.value) return;
    useSetLegends({
      legends: legendsArray.value,
      hasMoreLegends: false,
      apexRef: tcChart.value?.$refs?.apex,
    });
  },
  { deep: true },
);

watch(() => dbResponses.value, () => {
  if (!isLineGraph.value) return;
  useUpdateSeries({
    legends: legendsArray.value,
    card: props.card,
    board: props.board,
    db: false,
    previewMode: props.previewMode,
  });
  useSetLegends({
    legends: legendsArray.value,
    hasMoreLegends: false,
    apexRef: tcChart.value?.$refs?.apex,
  });
}, { deep: true });

</script>
