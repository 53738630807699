import { klona } from 'klona';
import { isEmpty, debounce } from 'lodash-es';
import { isBefore, isAfter, differenceInMonths } from 'date-fns';
import { format } from 'Utils/dateHelpers';
import { store } from '@/store';

export function useLineGraph() {
  function useSetLegends({ legends = [], hasMoreLegends, apexRef, timeout = 50 }) {
    setTimeout(() => {
      if (apexRef && legends?.length) {
        legends?.forEach((legend, idx) => {
          if (hasMoreLegends && idx === legends.length - 1) return;
          if (legend?.visible === false) apexRef?.hideSeries?.(legend?.name);
        });
      }
    }, timeout);
  }

  const useDebouncedUpdateCard = debounce((card) => {
    store.dispatch('updateCard', { card, apiCall: true });
  }, 1000);

  function useUpdateSeries({ legends = [], card = {}, board = {}, db = true, previewMode = false }) {
    if (isEmpty(card)) return;
    if (previewMode) {
      store.dispatch('setModalCardPath', {
        path: 'metadata.show.graph.series',
        value: legends,
      });
    } else {
      const localCard = klona(card);
      localCard.metadata.show.graph.series = legends;
      if (board?.id) {
        if (db) useDebouncedUpdateCard(localCard);
        else {
          store.dispatch('updateCard', { card: localCard, apiCall: true });
        }
      }
    }
  }
  return {
    useSetLegends,
    useUpdateSeries,
  };
}

export function useLineGraphResponseRate() {
  const getCompleteDateRange = (arr1, arr2) => {
    const firstDateArr1 = new Date(arr1[0]?.date_dt);
    const firstDateArr2 = new Date(arr2[0]?.date_dt);
    const lastDateArr1 = new Date(arr1[arr1.length - 1]?.date_dt);
    const lastDateArr2 = new Date(arr2[arr2.length - 1]?.date_dt);

    const useFirst = isBefore(firstDateArr1, firstDateArr2) ? firstDateArr1 : firstDateArr2;
    const useLast = isAfter(lastDateArr1, lastDateArr2) ? lastDateArr1 : lastDateArr2;

    const dateArray = [];
    const currentDate = new Date(useFirst);
    while (differenceInMonths(currentDate, useLast) <= 0) {
      dateArray.push({ str: format(currentDate, 'yyyy-MM'), dt: format(currentDate, 'yyyy-MM-dd') });
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return dateArray;
  };

  const fillMissingDates = (entries, dates) => dates.map((date) => {
    const matchingEntry = entries?.find((entry) => entry.date_dt === date.dt);
    return matchingEntry || {
      date_dt: date.dt,
      date_str: date.str,
      respond_perc: null,
      responded_count: null,
      applicant_count: null,
    };
  });

  const prepareLineGraphDbResponses = (responses, shouldTrimBenchmark) => {
    // 1. get the dates from responses and all the missing dates between
    const dateRange = getCompleteDateRange(responses[0], responses[1]);

    // 2. filter the benchmark data to only include the dates that are in the dateRange if shouldTrimBenchmark
    const benchmarkData = shouldTrimBenchmark ? responses[2]?.filter((entry) => {
      const entryDate = entry.date_dt;
      return dateRange.some((date) => date.dt === entryDate);
    }) : responses[2];

    // 3. fill the responses with the missing dates
    const segmentResponse = fillMissingDates(responses[0], dateRange);
    const customerResponse = fillMissingDates(responses[1], dateRange);
    const benchmarkResponse = fillMissingDates(benchmarkData, dateRange);

    return [segmentResponse, customerResponse, benchmarkResponse];
  };
  return {
    prepareLineGraphDbResponses,
  };
}
